import React from 'react';

import AppStore from '_content/images/app-store/app-store-badge.png';
import GooglePlay from '_content/images/app-store/google-play-badge.png';

export default function AppStoreBadges() {
    return (
        <div className="app-store-badges">
            <a href="https://apps.apple.com/us/app/suits-me-banking/id1550972210">
                <img src={AppStore} alt="App Store Badge" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.suitsme.app">
                <img src={GooglePlay} alt="Google Play Badge" />
            </a>
        </div>
    );
}
