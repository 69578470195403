import ModalOuterContainer from '../generic/containers/ModalOuterContainer';
import Typography from 'components/shared/generic/typography/presentational/Typography';
import useTranslate from 'lib/languages/useTranslate';
import AppStoreBadges from '../../layout/loginTemplate/presentational/AppStoreBadges';
import Button from 'components/shared/generic/button/presentational/Button';
import { hideModal } from 'actions/modal';
import { useDispatch } from 'react-redux';

const DownloadAppModal = () => {
    const dispatch = useDispatch();
    const translate = useTranslate('loginWeb');

    const handleHideModal = () => {
        dispatch(hideModal());
    }

    return (
        <ModalOuterContainer customButtons={true}>
            <div className="description">
                <Typography tag="h1">{translate('downloadApp')}</Typography>
            </div>
            <div className="app-store-badges-wrapper">
                <AppStoreBadges />
            </div>
            <Button className='closeBtn' onClick={handleHideModal}>Cancel</Button>
        </ModalOuterContainer>
    );
};

export default DownloadAppModal;
