import { useState } from 'react';
import { Login } from '../presentational/Login';
// import useRecaptcha from 'utils/hooks/useRecaptcha';
import { useForm } from 'lib/utils';
import { handleSignIn, initAmplify, UserSignInData } from 'lib/utils/amplify';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE } from 'lib/constants/amplify';
import { AUTH_EMAIL } from '../../../../constants/shared/routes';

import {
    REACT_APP_AMPLIFY_POOL_ID,
    REACT_APP_AMPLIFY_POOL_CLIENT_ID,
} from 'config';
import { setAuthStep } from 'utils/generic';
import { AuthStep } from 'constants/shared/enums';
import { ErrorName } from 'lib/type/enums';
import { useDispatch } from 'react-redux';
import { showModal } from 'actions/modal';
import { DOWNLOAD_APP } from 'constants/shared/modalTypes';

initAmplify(REACT_APP_AMPLIFY_POOL_ID, REACT_APP_AMPLIFY_POOL_CLIENT_ID);

export const LoginContainer = () => {
    const history = useHistory();
    const [isPosting, setIsPosting] = useState(false);
    const [error, setError] = useState<string | null>('');
    const dispatch = useDispatch();
    // const [getCaptchaToken, captchaLoading] = useRecaptcha();  NOTE: Temporarily disabling reCAPTCHA.
    const [form, handleChange] = useForm({
        email: ''
    });

    const prepareUserSignInData = async (form: { username: string }): Promise<UserSignInData> => {
    //   const captchaToken = await getCaptchaToken();
      const { username } = form;
      const user: UserSignInData = {
          username: username.trim(),
        //   captchaToken,
      };
      return user;
  };

    const handleSubmit = async () => {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        return dispatch(
            showModal({
                type: DOWNLOAD_APP,
            }),
        );
      }
      setIsPosting(true);
      const user = await prepareUserSignInData(form);
        try {
            const signInResult = await handleSignIn(user);
            const { signInStep, additionalInfo } = signInResult.nextStep;
            if (signInStep === CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE) {
                localStorage.setItem(
                    'userInfo',
                    JSON.stringify({...additionalInfo, ...user}),
                );
                history.push(AUTH_EMAIL);
                setAuthStep(AuthStep.EMAIL_SIGN_IN_FLOW)
            }
        } catch (e) {
            const error = e as { message: string, name:string };
            if(error.name === ErrorName.USER_NOT_FOUND_EXCEPTION){
                setError(null)
                return
            }
            setError(error.message);
        } finally {
            setIsPosting(false);
        }
    };

    return (
        <Login
            {...form}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            isPosting={isPosting}
            error={error}
        />
    );
};
